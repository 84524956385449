<template>
  <b-col
    class="hp-profile-menu px-0 flex justify-content-start"
    style="flex: 0 0 240px"
  >
    <div class="w-100" v-if="currentUser">
      <div class="hp-profile-menu-header mt-16 mt-lg-0 text-center">
        <div class="hp-menu-header-btn mb-12 text-right"></div>
        <div class="d-flex justify-content-center">
          <div class="d-inline-block position-relative">
            <div
              class="profile-img-edit avatar-item mt-3"
              style="width: 120px; height: 120px"
            >
              <img
                class="rounded-circle"
                :src="
                  preview
                    ? preview
                    : require('@/assets/img/app/user/user-icon.png')
                "
              />
              <b-button variant="warning" class="p-image px-2" size="sm">
                <div class="position-relative" :id="idPicker">
                  <i class="ri-pencil-line upload-button"></i>
                </div>
              </b-button>
              <avatar-cropper
                :upload-handler="selectedFile"
                @changed="setFileName"
                :trigger="`#${idPicker}`"
                :labels="{
                  submit: 'Aceptar',
                  cancel: 'Cancelar',
                }"
                :cropper-options="{
                  aspectRatio: 1,
                  autoCropArea: 1,
                  viewMode: 1,
                  movable: false,
                  zoomable: true,
                }"
              />
            </div>
          </div>
        </div>

        <h3 class="mt-24 mb-4">
          {{
            currentUser.first_name
              ? `${currentUser.first_name} ${currentUser.last_name}`
              : "Administrador"
          }}
        </h3>
        <a href="#" class="hp-p1-body">
          {{ currentUser.email }}
        </a>
      </div>
    </div>

    <div class="hp-profile-menu-body w-100 text-left mt-5 pt-5">
      <ul class="mt-5">
        <li class="">
          <b-link
            to="/pages/profile/personel-information"
            :class="`position-relative text-black-80 hp-text-color-dark-30 hp-hover-text-color-primary-1 hp-hover-text-color-dark-0 py-12 px-24 d-flex align-items-center${
              path == '/pages/profile/personel-information' ? ' active' : ''
            }`"
          >
            <i class="iconly-Curved-User mr-8"></i>
            <span>Información personal</span>

            <span
              class="hp-menu-item-line position-absolute h-100 bg-success hp-bg-dark-0"
              style="width: 2px"
            ></span>
          </b-link>
        </li>
      </ul>
    </div>
  </b-col>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AvatarCropper from "vue-avatar-cropper";
import { dataURLtoFile } from "@/utils/common";
export default {
  props: {
    idPicker: {
      type: String,
    },
  },
  data() {
    return {
      path: "",
      fileSelected: null,
      preview: "",
    };
  },
  components: {
    AvatarCropper,
  },
  watch: {
    $route() {
      this.getRoute();
    },
  },
  methods: {
    ...mapActions({
      updateProfileUser: "user/updateProfileUser",
    }),
    setPreviewImage(user) {
      this.preview = user.image ?? "";
    },
    getRoute() {
      this.path = this.$route.path;
    },
    async selectedFile(cropper) {
      const dataUrl = cropper
        .getCroppedCanvas()
        .toDataURL(this.fileSelected.type);
      const file = dataURLtoFile(dataUrl, this.fileSelected.name);
      this.preview = dataUrl;
      this.fileSelected = file;
      this.handleSubmit();
    },
    //Covert any dataURL to file Object JS
    setFileName(file) {
      console.log(file);
      this.fileSelected = file;
    },
    async handleSubmit() {
      this.loading = true;
      const { first_name, last_name, email, rut, phone } = this.currentUser;
      const response = await this.updateProfileUser({
        id: this.currentUser.id,
        first_name,
        last_name,
        email,
        rut,
        phone,
        image: this.fileSelected,
      });
      this.loading = false;
      if (response.status == 201 || response.status == 200) {
        this.$bvToast.toast(`Imagen de perfil actualizada correctamente`, {
          title: `Excelente`,
          variant: "primary",
          solid: false,
        });
      }
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  watch: {
    currentUser: {
      immediate: true,
      handler(user) {
        this.setPreviewImage(user);
      },
    },
  },
  created() {
    this.getRoute();
  },
};
</script>
<style>
.profile-img-edit {
  position: relative;
  display: inline-block;
  height: 150px;
  width: 150px;
}

.p-image {
  position: absolute;
  top: auto;
  right: 1px;
  bottom: -8px;
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  border-radius: 50%;
  height: 30px;
  width: 30px;
  text-align: center;
  cursor: pointer;
  z-index: 50;
}
</style>
