var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-col',{staticClass:"hp-profile-menu px-0 flex justify-content-start",staticStyle:{"flex":"0 0 240px"}},[(_vm.currentUser)?_c('div',{staticClass:"w-100"},[_c('div',{staticClass:"hp-profile-menu-header mt-16 mt-lg-0 text-center"},[_c('div',{staticClass:"hp-menu-header-btn mb-12 text-right"}),_c('div',{staticClass:"d-flex justify-content-center"},[_c('div',{staticClass:"d-inline-block position-relative"},[_c('div',{staticClass:"profile-img-edit avatar-item mt-3",staticStyle:{"width":"120px","height":"120px"}},[_c('img',{staticClass:"rounded-circle",attrs:{"src":_vm.preview
                  ? _vm.preview
                  : require('@/assets/img/app/user/user-icon.png')}}),_c('b-button',{staticClass:"p-image px-2",attrs:{"variant":"warning","size":"sm"}},[_c('div',{staticClass:"position-relative",attrs:{"id":_vm.idPicker}},[_c('i',{staticClass:"ri-pencil-line upload-button"})])]),_c('avatar-cropper',{attrs:{"upload-handler":_vm.selectedFile,"trigger":`#${_vm.idPicker}`,"labels":{
                submit: 'Aceptar',
                cancel: 'Cancelar',
              },"cropper-options":{
                aspectRatio: 1,
                autoCropArea: 1,
                viewMode: 1,
                movable: false,
                zoomable: true,
              }},on:{"changed":_vm.setFileName}})],1)])]),_c('h3',{staticClass:"mt-24 mb-4"},[_vm._v(" "+_vm._s(_vm.currentUser.first_name ? `${_vm.currentUser.first_name} ${_vm.currentUser.last_name}` : "Administrador")+" ")]),_c('a',{staticClass:"hp-p1-body",attrs:{"href":"#"}},[_vm._v(" "+_vm._s(_vm.currentUser.email)+" ")])])]):_vm._e(),_c('div',{staticClass:"hp-profile-menu-body w-100 text-left mt-5 pt-5"},[_c('ul',{staticClass:"mt-5"},[_c('li',{},[_c('b-link',{class:`position-relative text-black-80 hp-text-color-dark-30 hp-hover-text-color-primary-1 hp-hover-text-color-dark-0 py-12 px-24 d-flex align-items-center${
            _vm.path == '/pages/profile/personel-information' ? ' active' : ''
          }`,attrs:{"to":"/pages/profile/personel-information"}},[_c('i',{staticClass:"iconly-Curved-User mr-8"}),_c('span',[_vm._v("Información personal")]),_c('span',{staticClass:"hp-menu-item-line position-absolute h-100 bg-success hp-bg-dark-0",staticStyle:{"width":"2px"}})])],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }