<template>
  <b-row>
    <b-col cols="12">
      <b-row align-v="center" align-h="between" class="mt-5">
        <b-col cols="12" md="6">
          <h3>Información Personal</h3>
        </b-col>

        <b-col cols="12" class="hp-profile-content-list mt-8 pb-0 pb-sm-16">
          <ul>
            <li>
              <span class="hp-p1-body">Nombre</span>
              <span
                class="mt-0 mt-sm-4 hp-p1-body text-black-100 hp-text-color-dark-0"
              >
                {{
                  currentUser.first_name
                    ? currentUser.first_name
                    : "Administrador"
                }}
              </span>
            </li>
            <li class="mt-18">
              <span class="hp-p1-body">Apellido</span>
              <span
                class="mt-0 mt-sm-4 hp-p1-body text-black-100 hp-text-color-dark-0"
              >
                {{
                  currentUser.last_name
                    ? currentUser.last_name
                    : "Administrador"
                }}
              </span>
            </li>
            <li class="mt-18">
              <span class="hp-p1-body">Correo electrónico</span>
              <span
                class="mt-0 mt-sm-4 hp-p1-body text-black-100 hp-text-color-dark-0"
              >
                {{ currentUser.email }}
              </span>
            </li>
            <li class="mt-18">
              <span class="hp-p1-body">RUT</span>
              <span
                class="mt-0 mt-sm-4 hp-p1-body text-black-100 hp-text-color-dark-0"
              >
                {{ currentUser.rut ? currentUser.rut : "Sin RUT" }}
              </span>
            </li>
            <li class="mt-18">
              <span class="hp-p1-body">Teléfono</span>
              <a
                class="mt-0 mt-sm-4 hp-p1-body text-black-100 hp-text-color-dark-0"
              >
                {{ currentUser.phone ? currentUser.phone : "Sin teléfono" }}
              </a>
            </li>
          </ul>
        </b-col>
      </b-row>
    </b-col>

    <div class="divider mx-16 border-black-40 hp-border-color-dark-80"></div>

    <b-col cols="12">
      <b-row align-v="center" align-h="between">
        <b-col cols="12" md="6">
          <h3>Acciones</h3>
          <b-button variant="warning" class="btn-ghost mt-3" @click="show">
            Actualizar
          </b-button>
        </b-col>
      </b-row>
    </b-col>

    <b-modal
      id="modal-edit-contact"
      cancel-variant="text"
      body-class="py-16"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      hide-footer
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 class="mb-0">Actualizar Información Personal</h5>

          <b-button
            variant="text"
            @click="close()"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            ></i>
          </b-button>
        </div>

        <div class="divider my-0 p-0"></div>
      </template>

      <form>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Nombre*">
              <b-form-input
                v-model="form.first_name"
                id="firstName"
                :class="{ 'is-invalid': $v.form.first_name.$error }"
                type="text"
                placeholder="Ingresa el nombre"
              ></b-form-input>
              <div v-if="$v.form.first_name.$error" class="invalid-feedback">
                <span v-if="!$v.form.first_name.required"
                  >Nombre es requerido.</span
                >
              </div>
            </b-form-group>
          </b-col>

          <b-col cols="12" class="mt-8">
            <b-form-group label="Apellido*">
              <b-form-input
                v-model="form.last_name"
                id="lastName"
                :class="{ 'is-invalid': $v.form.last_name.$error }"
                type="text"
                placeholder="Ingresa el apellido"
              ></b-form-input>
              <div v-if="$v.form.last_name.$error" class="invalid-feedback">
                <span v-if="!$v.form.last_name.required"
                  >Apellido es requerido.</span
                >
              </div>
            </b-form-group>
          </b-col>

          <b-col cols="12" class="mt-8">
            <b-form-group label="Correo electrónico*">
              <b-form-input
                v-model="form.email"
                id="email"
                :class="{ 'is-invalid': $v.form.email.$error }"
                type="email"
                placeholder="Ingresa el correo electrónico"
              ></b-form-input>
              <div v-if="$v.form.email.$error" class="invalid-feedback">
                <span v-if="$v.form.email.$error"
                  >Correo electrónico es requerido y debe tener un formato
                  valido de correo.</span
                >
              </div>
            </b-form-group>
          </b-col>

          <b-col cols="12" class="mt-8">
            <b-form-group label="RUT*">
              <b-form-input
                v-model="form.rut"
                id="rut"
                :class="{ 'is-invalid': $v.form.rut.$error }"
                type="text"
                placeholder="Ingresa el RUT"
              ></b-form-input>
              <div v-if="$v.form.rut.$error" class="invalid-feedback">
                <span v-if="!$v.form.rut.required">RUT es requerido.</span>
              </div>
            </b-form-group>
          </b-col>

          <b-col cols="12" class="mt-8">
            <b-form-group label="Teléfono*">
              <b-form-input
                v-model="form.phone"
                id="phone"
                type="text"
                :class="{ 'is-invalid': $v.form.phone.$error }"
                placeholder="Ingresa el teléfono"
              ></b-form-input>
              <div v-if="$v.form.phone.$error" class="invalid-feedback">
                <span v-if="!$v.form.phone.required"
                  >El teléfono es requerido.</span
                >
              </div>
            </b-form-group>
          </b-col>

          <b-col cols="6 pr-12" class="mt-3">
            <b-button variant="outline-primary" block @click="handleSubmit">
              <b-spinner
                v-if="loading"
                small
                type="grow"
                class="mr-8"
              ></b-spinner>
              Actualizar
            </b-button>
          </b-col>

          <b-col cols="6 pl-12" class="mt-3">
            <b-button
              variant="outline-info-3"
              :disabled="loading"
              block
              @click="$bvModal.hide('modal-edit-contact')"
            >
              Cerrar
            </b-button>
          </b-col>
        </b-row>
      </form>
    </b-modal>
  </b-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
const { required, email } = require("vuelidate/lib/validators");

export default {
  mixins: [validationMixin],
  data() {
    return {
      loading: false,
      form: {
        first_name: "",
        last_name: "",
        email: "",
        rut: "",
        phone: "",
      },
    };
  },
  validations: {
    form: {
      first_name: {
        required,
      },
      last_name: {
        required,
      },
      email: {
        required,
        email,
      },
      rut: {
        required,
      },
      phone: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  methods: {
    ...mapActions({
      updateProfileUser: "user/updateProfileUser",
    }),
    async handleSubmit() {
      this.$v.$touch();
      this.$v.form.$touch();
      if (!this.$v.form.$anyError) {
        this.loading = true;
        const response = await this.updateProfileUser({
          id: this.currentUser.id,
          ...this.form,
        });
        this.loading = false;
        if (response.status == 201 || response.status == 200) {
          this.$bvToast.toast(`Perfil actualizado correctamente`, {
            title: `Excelente`,
            variant: "primary",
            solid: false,
          });
          this.closeModal();
        }
      }
    },
    closeModal() {
      this.$bvModal.hide("modal-edit-contact");
      this.form = {
        first_name: "",
        last_name: "",
        email: "",
        rut: "",
        phone: "",
      };
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    show() {
      this.$nextTick(() => {
        this.$v.$reset();
      });
      this.form = {
        first_name: this.currentUser.first_name
          ? this.currentUser.first_name
          : "Administrador",
        last_name: this.currentUser.last_name
          ? this.currentUser.last_name
          : "Administrador",
        email: this.currentUser.email,
        rut: this.currentUser.rut,
        phone: this.currentUser.phone,
      };
      this.$bvModal.show("modal-edit-contact");
    },
  },
};
</script>
